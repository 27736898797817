var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("h1", { staticClass: "title mb-0 mr-3" }, [
            _vm._v(_vm._s(_vm.$t("lk.header.subscribeConstructor"))),
          ]),
          _vm.loadingTariffs
            ? _c("CSpinner", { attrs: { color: "primary", size: "sm" } })
            : _vm._e(),
        ],
        1
      ),
      !_vm.loadingTariffs
        ? _c(
            "CRow",
            _vm._l(_vm.subscribeData, function (item, i) {
              return _c(
                "CCol",
                { key: i, staticClass: "mt-3", attrs: { col: "12", xl: "4" } },
                [
                  _c("card-wrap", {
                    attrs: { "init-item": item, save: _vm.save },
                  }),
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }