var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: _vm.item.active === false ? "opacity: 0.5" : "" },
    [
      !_vm.isEdit
        ? _c(
            "CButton",
            {
              staticClass: "shadow-none",
              attrs: { color: "dark", block: "" },
              on: {
                click: function ($event) {
                  _vm.isEdit = true
                },
              },
            },
            [
              _c("CIcon", {
                staticClass: "m-0",
                attrs: { name: "cil-pencil" },
              }),
              _vm._v(" " + _vm._s(_vm.$t("general.edit")) + " "),
            ],
            1
          )
        : _vm._e(),
      _vm.isEdit
        ? _c(
            "CButton",
            {
              staticClass: "shadow-none",
              attrs: { color: "dark", block: "" },
              on: {
                click: function ($event) {
                  _vm.isEdit = false
                  _vm.init()
                },
              },
            },
            [
              _c("CIcon", { staticClass: "m-0", attrs: { name: "cil-x" } }),
              _vm._v(" " + _vm._s(_vm.$t("general.cancel")) + " "),
            ],
            1
          )
        : _vm._e(),
      _vm.isEdit
        ? _c("subscribe-card-editable", {
            attrs: {
              "tariff-id": _vm.item.id,
              color: _vm.item.color,
              title: _vm.item.name,
              description: _vm.item.description,
              "month-list": _vm.item.variants,
              "function-list": _vm.item.features,
              "available-pages": _vm.item.access,
            },
            on: {
              setTariffId: function ($event) {
                _vm.item.id = $event
              },
              setColor: function ($event) {
                _vm.item.color = $event
              },
              setTitle: function ($event) {
                _vm.item.name[$event.locale] = $event.value
              },
              setDescription: function ($event) {
                _vm.item.description[$event.locale] = $event.value
              },
            },
          })
        : _c("subscribe-card-admin", {
            attrs: {
              active: _vm.item.active,
              color: _vm.item.color,
              title: _vm.item.name,
              description: _vm.item.description,
              "month-list": _vm.item.variants,
              "function-list": _vm.item.features,
              "available-pages": _vm.item.access,
            },
          }),
      _vm.isEdit
        ? _c(
            "CLoadingButtonCustom",
            {
              staticClass: "shadow-none",
              attrs: {
                color: "success",
                loading: _vm.loadingRequest,
                disabled: _vm.loadingRequest,
                block: "",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.saveClick.apply(null, arguments)
                },
              },
            },
            [
              _c("CIcon", { staticClass: "m-0", attrs: { name: "cil-save" } }),
              _vm._v(" " + _vm._s(_vm.$t("general.saveChanges")) + " "),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }