<template>
  <div>
    <div class="header">
      <h1 class="title mb-0 mr-3">{{$t('lk.header.subscribeConstructor')}}</h1>
      <CSpinner
        v-if="loadingTariffs"
        color="primary"
        size="sm"
      />
    </div>

    <CRow v-if="!loadingTariffs">
      <CCol
        v-for="(item, i) in subscribeData"
        :key="i"
        class="mt-3"
        col="12"
        xl="4"
      >
        <card-wrap
          :init-item="item"
          :save="save"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import CardWrap from "@/components/subscribe/CardWrap";
import axios from "axios";
import cloneDeep from "lodash/cloneDeep";
export default {
  name: "SubscribeConstructor",
  components: {
    CardWrap
  },
  data () {
    return {
      subscribeData: [],
      loadingTariffs: false
    }
  },
  created() {
    this.getTariffs()
  },
  computed: {
    locale () {
      return this.$root.$i18n.locale
    }
  },
  methods: {
    getTariffs () {
      this.loadingTariffs = true
      return axios.get('/api/v2/tariffs/control')
        .then(({data, status}) => {
          if (status < 300) {
            this.subscribeData = data.data
          }
        })
        .finally(() => {
          this.loadingTariffs = false
        })
    },
    save (tariff) {
      const data = cloneDeep(tariff)
      data.access = data.access.filter(i => {
        if (i.enabled) return i
      })

      return axios.put('/api/v2/tariffs/control', data)
        .then((res) => {
          if (res.status < 300) {
            const id = data.id
            const index = this.subscribeData.findIndex(i => i.id === id)
            this.subscribeData.splice(index, 1, res.data.data.find(i => i.id === id))
            return res
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>

.header {
  position: relative;
  display: flex;
  align-items: center;

  @include media-breakpoint-down(xs) {
    margin-bottom: 0.25rem;

    .title {
      display: none;
    }
    .cbtn {
      width: 100%;
    }
  }
  @include media-breakpoint-up(sm) {
    margin-bottom: 0.75rem;

    .title {
      font-size: 1.25rem;
    }
  }
  @include media-breakpoint-up(xl) {
    margin-top: 1.75rem;
    margin-bottom: 0;

    .title {
      font-size: 2.25rem;
    }
  }
}

.subscribe-cards {
  @include media-breakpoint-down(lg) {
    flex-direction: column;
  }
}
</style>
