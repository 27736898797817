<template>
  <div :style="(item.active === false ? 'opacity: 0.5' : '')">
    <CButton
      v-if="!isEdit"
      color="dark"
      class="shadow-none"
      block
      @click="isEdit = true"
    >
      <CIcon class="m-0" name="cil-pencil" />
      {{ $t('general.edit') }}
    </CButton>
    <CButton
      v-if="isEdit"
      color="dark"
      class="shadow-none"
      block
      @click="isEdit = false; init()"
    >
      <CIcon class="m-0" name="cil-x" />
      {{ $t('general.cancel') }}
    </CButton>

    <subscribe-card-editable
      v-if="isEdit"
      :tariff-id="item.id"
      @setTariffId="item.id = $event"
      :color="item.color"
      @setColor="item.color = $event"
      :title="item.name"
      @setTitle="item.name[$event.locale] = $event.value"
      :description="item.description"
      @setDescription="item.description[$event.locale] = $event.value"
      :month-list="item.variants"
      :function-list="item.features"
      :available-pages="item.access"
    />
    <subscribe-card-admin
      v-else
      :active="item.active"
      :color="item.color"
      :title="item.name"
      :description="item.description"
      :month-list="item.variants"
      :function-list="item.features"
      :available-pages="item.access"
    />

    <CLoadingButtonCustom
      v-if="isEdit"
      color="success"
      class="shadow-none"
      :loading="loadingRequest"
      :disabled="loadingRequest"
      block
      @click.native="saveClick"
    >
      <CIcon class="m-0" name="cil-save" />
      {{ $t('general.saveChanges') }}
    </CLoadingButtonCustom>
  </div>
</template>

<script>
import SubscribeCardAdmin from "@/components/subscribe/SubscribeCardAdmin";
import SubscribeCardEditable from "@/components/subscribe/SubscribeCardEditable";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "CardWrap",
  components: {
    SubscribeCardAdmin,
    SubscribeCardEditable
  },
  props: {
    initItem: {
      type: Object,
      required: true
    },
    save: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      isEdit: false,
      item: null,
      loadingRequest: false
    }
  },
  created() {
    this.init()
  },
  methods: {
    init () {
      this.item = cloneDeep(this.initItem)
    },
    saveClick () {
      this.loadingRequest = true

      this.save(this.item)
        .then(({status}) => {
          if (status < 300) {
            this.isEdit = false
          }
        })
        .finally(() => {
          this.loadingRequest = false
        })
    }
  }
}
</script>

<style scoped>

</style>
