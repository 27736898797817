var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    { staticClass: "shadow-none h-100 mb-0" },
    [
      _c(
        "CCardBody",
        { staticClass: "card-body-custom" },
        [
          _vm.active === false
            ? _c("CBadge", { staticClass: "text-lowercase" }, [
                _vm._v(_vm._s(_vm.$t("lk.subscribe.inactive"))),
              ])
            : _vm._e(),
          _c(
            "h2",
            {
              staticClass: "title mt-2 mb-3 text-uppercase font-weight-bold",
              style: _vm.titleStyle,
            },
            [_vm._v(" " + _vm._s(_vm.title[_vm.locale].toUpperCase()) + " ")]
          ),
          _c("p", {
            staticClass: "description mb-1",
            domProps: { innerHTML: _vm._s(_vm.description[_vm.locale]) },
          }),
          _c(
            "div",
            { staticClass: "d-flex" },
            _vm._l(_vm.monthList, function (item, i) {
              return _c("div", { key: i, staticClass: "w-25 mr-1" }, [
                _c("div", { staticClass: "d-flex align-items-start" }, [
                  _c("h4", { staticClass: "mb-1 mr-1" }, [
                    _vm._v("$" + _vm._s(item.price)),
                  ]),
                  item.oldPrice
                    ? _c("span", { staticClass: "price-strike text-muted" }, [
                        _vm._v(" $" + _vm._s(item.oldPrice) + " "),
                      ])
                    : _vm._e(),
                ]),
                _c("p", { staticClass: "mb-0 text-muted timespan" }, [
                  _vm._v(" " + _vm._s(item.name[_vm.locale]) + " "),
                ]),
              ])
            }),
            0
          ),
          _vm.functionList.length
            ? _c("p", { staticClass: "font-weight-bold functions-title" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("lk.subscribeConstructor.mainFunctions")) +
                    " "
                ),
              ])
            : _vm._e(),
          _c(
            "ul",
            { staticClass: "list text-muted" },
            _vm._l(_vm.functionList, function (item, i) {
              return _c("li", {
                key: i,
                domProps: { innerHTML: _vm._s(item[_vm.locale]) },
              })
            }),
            0
          ),
          _c("p", { staticClass: "font-weight-bold functions-title" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("lk.subscribeConstructor.availablePages")) +
                " "
            ),
          ]),
          _c(
            "ul",
            { staticClass: "list text-muted" },
            [
              _vm._l(_vm.availablePages, function (item, i) {
                return [
                  item.enabled
                    ? _c("li", {
                        key: i,
                        domProps: { innerHTML: _vm._s(item.name[_vm.locale]) },
                      })
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }