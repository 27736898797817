<template>
  <CCard
    class="shadow-none h-100 mb-0"
  >
    <CCardBody class="card-body-custom">
      <CBadge
        v-if="active === false"
        class="text-lowercase"
      >{{ $t('lk.subscribe.inactive') }}</CBadge>

      <h2
        class="title mt-2 mb-3 text-uppercase font-weight-bold"
        :style="titleStyle"
      >
        {{title[locale].toUpperCase()}}
      </h2>

      <p
        class="description mb-1"
        v-html="description[locale]"
      />

      <div class="d-flex">
        <div
          v-for="(item, i) in monthList"
          :key="i"
          class="w-25 mr-1"
        >
          <div class="d-flex align-items-start">
            <h4 class="mb-1 mr-1">${{item.price}}</h4>
            <span v-if="item.oldPrice" class="price-strike text-muted">
              ${{ item.oldPrice }}
            </span>
          </div>
          <p class="mb-0 text-muted timespan">
            {{item.name[locale]}}
          </p>
        </div>
      </div>

      <p v-if="functionList.length" class="font-weight-bold functions-title">
        {{ $t('lk.subscribeConstructor.mainFunctions') }}
      </p>
      <ul class="list text-muted">
        <li
          v-for="(item, i) in functionList"
          :key="i"
          v-html="item[locale]"
        />
      </ul>

      <p class="font-weight-bold functions-title">
        {{ $t('lk.subscribeConstructor.availablePages') }}
      </p>
      <ul class="list text-muted">
        <template
          v-for="(item, i) in availablePages"
        >
          <li
            v-if="item.enabled"
            v-html="item.name[locale]"
            :key="i"
          />
        </template>
      </ul>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  name: "SubscribeCard",
  props: {
    active:{
      type: Boolean,
      default: null
    },
    color: {
      type: String,
      default: '#ffffff'
    },
    title: {
      type: Object,
      required: true
    },
    description: {
      type: Object,
      required: true
    },
    monthList: {
      type: Array,
      default: () => ([])
    },
    functionList: {
      type: Array,
      default: () => ([])
    },
    availablePages: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    titleStyle () {
      return {
        color: this.color
      }
    },
    locale () {
      return this.$root.$i18n.locale
    }
  },
  methods: {
    hexToRgb (color) {
      /* Check for # infront of the value, if it's there, strip it */
      if(color.substring(0,1) === '#') {
        color = color.substring(1)
      }

      let rgbColor = {}

      /* Grab each pair (channel) of hex values and parse them to ints using hexadecimal decoding */
      rgbColor.r = parseInt(color.substring(0,2),16)
      rgbColor.g = parseInt(color.substring(2,4),16)
      rgbColor.b = parseInt(color.substring(4),16)

      return rgbColor
    },
    defineBgLight () {
      const { r, g, b } = this.hexToRgb(this.color)
      const lighten  = 0.2126 * r + 0.7152 * g + 0.0722 * b
      return lighten > 0.5
    }
  }
}
</script>

<style lang="scss" scoped>
.card-body-custom {
  box-shadow: 0 9px 16px 10px rgba(61, 61, 61, 0.08);

  @include media-breakpoint-down(xs) {
    padding: 1rem 1.25rem;

    .timespan {
      font-size: 0.75rem;
    }
  }
  @include media-breakpoint-up(sm) {
    padding: 1.5rem 2rem;
  }
  @include media-breakpoint-up(xl) {
    padding: 2rem;
  }
}
.c-dark-theme {
  .card-body-custom {
    background: var(--subscribe-card-bg);
    box-shadow: none
  }
}
.title {
  font-size: 2rem;
}
.description {
  min-height: calc(0.875rem * 1.5 * 3); // минимальная высота 2 строки (с учётом font-size и line-height)
}
.price {
  font-size: 2.25rem;
}
.month {
  font-size: 1rem;
}
.list {
  margin: 0;
  padding-left: 1rem;
  li {
    margin-bottom: 0.8rem;
    &::marker {
      color: var(--primary);
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.button-custom {
  max-width: 18rem;
}

.price-strike {
  vertical-align: top;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 110%;
    height: 1px;
    transform: rotate(330deg) translateY(-50%);
    background: #768192;
  }
}

.c-dark-theme {
  .price-strike {
    &:after {
      background: rgba(255, 255, 255, 0.6);
    }
  }
}

.tooltip-wrap {
  margin-left: 0.3rem;
  cursor: pointer;
  color: #fff;
  width: 1rem;
  height: 1rem;
}

.functions-title {
  margin-top: 3rem;
}

</style>
