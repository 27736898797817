<template>
  <CCard
    class="shadow-none h-100 mb-0"
  >
    <CCardBody class="card-body-custom">
      <div class="group-custom">
        <CInput
          prepend="ru"
          :value="title.ru"
          :label="`${$t('lk.subscribeConstructor.subscriptionName')}:`"
          :placeholder="$t('lk.subscribeConstructor.name')"
          @input="$emit('setTitle', {locale: 'ru', value: $event})"
        />
        <CInput
          prepend="en"
          :value="title.en"
          :placeholder="$t('lk.subscribeConstructor.name')"
          @input="$emit('setTitle', {locale: 'en', value: $event})"
        />
      </div>

      <CInput
        :value="tariffId"
        :label="`${$t('lk.subscribeConstructor.subscriptionID')}:`"
        placeholder="anyTariffId"
        disabled
        :description="$t('lk.subscribeConstructor.requiredForTheServerSide')"
        @input="$emit('setTariffId', $event)"
      />

      <v-select-input
        v-model="colorComputed"
        :options="colorOptions"
        :searchable="false"
        :clearable="false"
        :placeholder="$t('lk.subscribeConstructor.chooseColor')"
      >
        <template #option="{option}">
          <div
            class="circle-color"
            :style="{background: option.value}"
          />
          <span>{{option.label}}</span>
        </template>
        <template #selected-option="{option}">
          <div
            class="circle-color"
            :style="{background: option.value}"
          />
          <span>{{option.label}}</span>
        </template>
      </v-select-input>

      <div class="group-custom">
        <CInput
          prepend="ru"
          :value="description.ru"
          :label="`${$t('lk.subscribeConstructor.subscriptionDescription')}:`"
          :placeholder="$t('lk.subscribeConstructor.description')"
          @input="$emit('setDescription', {locale: 'ru', value: $event})"
        />
        <CInput
          prepend="en"
          :value="description.en"
          :placeholder="$t('lk.subscribeConstructor.description')"
          @input="$emit('setDescription', {locale: 'en', value: $event})"
        />
      </div>

      <label>{{ $t('lk.subscribeConstructor.subscriptionCost') }}:</label>
      <div class="month-group">
        <CRow
          v-for="(item, i) in monthList"
          :key="i"
          class="align-items-end mb-2"
        >
          <CCol col="5">
            <CInput
              class="mb-1"
              :value="monthList[i].price"
              :label="monthList[i].name[locale]"
              :placeholder="$t('lk.subscribeConstructor.price')"
              @input="setPrice(i, $event)"
            >
              <template #append-content>$</template>
            </CInput>
          </CCol>
          <CCol col="7">
            <CInput
              class="mb-1"
              :value="monthList[i].oldPrice"
              :placeholder="$t('lk.subscribeConstructor.price')"
              @input="setOldPrice(i, $event)"
            >
              <template #prepend>
                <span class="input-span-custom" v-html="$t('lk.subscribeConstructor.oldPrice') + ':'" />
              </template>
              <template #append-content>$</template>
            </CInput>
          </CCol>
          <CCol col="12">
            <span v-if="monthList[i].savePct" class="text-subscribe-save-pct">
              {{ $t('lk.subscribeConstructor.amountOfDiscount') }} {{monthList[i].savePct}}%
            </span>
          </CCol>
        </CRow>
      </div>

      <p class="font-weight-bold functions-title">{{ $t('lk.subscribeConstructor.mainFunctions') }}</p>
      <div>
        <div
          v-for="(item, i) in functionList"
          :key="i"
          class="group-custom"
        >
          <div class="d-flex align-items-center mb-3">
            <div class="flex-grow-1">
              <CInput
                prepend="ru"
                :value="item.ru"
                @input="functionList[i].ru = $event"
              />
              <CInput
                class="mb-0"
                prepend="en"
                :value="item.en"
                @input="functionList[i].en = $event"
              />
            </div>
            <CButton
              class="shadow-none ml-2"
              color="danger"
              @click="functionList.splice(i, 1)"
            >
              <CIcon name="cil-x" />
            </CButton>
          </div>
        </div>
      </div>

      <CButton
        color="primary"
        variant="outline"
        class="shadow-none"
        @click="functionList.push({en: '', ru: ''})"
      >
        <CIcon name="cil-plus" />
        {{ $t('lk.subscribeConstructor.addLine') }}
      </CButton>

      <p class="font-weight-bold functions-title">{{ $t('lk.subscribeConstructor.availablePages') }}</p>
      <div class="month-group">
        <CInputCheckbox
          v-for="(item, i) in availablePages"
          custom
          :key="i"
          :label="item.name[locale]"
          :checked="item.enabled"
          @update:checked="item.enabled = $event"
        />
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import VSelectInput from "@/components/VSelectInput";
export default {
  name: "SubscribeCard",
  components: {
    VSelectInput
  },
  props: {
    color: {
      type: String,
      default: '#ffffff'
    },
    title: {
      type: Object,
      required: true
    },
    tariffId: {
      type: String
    },
    description: {
      type: Object,
      required: true
    },
    monthList: {
      type: Array,
      default: () => ([])
    },
    functionList: {
      type: Array,
      default: () => ([])
    },
    availablePages: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      colorOptions: [
        {value:'#20C997', label: `${this.$t('general.color')} 1`},
        {value:'#F8CB00', label: `${this.$t('general.color')} 2`},
        {value:'#E83E8C', label: `${this.$t('general.color')} 3`},
        {value:'#1D908A', label: `${this.$t('general.color')} 4`},
        {value:'#20A8D8', label: `${this.$t('general.color')} 5`},
        {value:'#45a164', label: `${this.$t('general.color')} 6`},
        {value:'#d16767', label: `${this.$t('general.color')} 7`}
      ]
    }
  },
  computed: {
    colorComputed: {
      set (e) {
        this.$emit('setColor', e.value)
      },
      get () {
        return this.colorOptions.find(i => i.value === this.color)
      }
    },
    locale () {
      return this.$root.$i18n.locale
    }
  },
  methods: {
    setSavePct (index) {
      const price = this.monthList[index].price
      const oldPrice = this.monthList[index].oldPrice
      if (price && oldPrice) {
        this.monthList[index].savePct = Math.trunc((oldPrice - price) / oldPrice * 100)
      } else {
        this.monthList[index].savePct = null
      }
    },
    setPrice (index, e) {
      this.monthList[index].price = Number(e)
      this.setSavePct(index)
    },
    setOldPrice (index, e) {
      this.monthList[index].oldPrice = Number(e)
      this.setSavePct(index)
    }
  }
}
</script>

<style lang="scss" scoped>
.card-body-custom {
  box-shadow: 0 9px 16px 10px rgba(61, 61, 61, 0.08);

  @include media-breakpoint-down(xs) {
    padding: 1rem 1.25rem;
  }
  @include media-breakpoint-up(sm) {
    padding: 1.5rem 2rem;
  }
  @include media-breakpoint-up(xl) {
    padding: 2rem;
  }
}
.c-dark-theme {
  .card-body-custom {
    background: var(--subscribe-card-bg);
    box-shadow: none
  }
}
.title {
  font-size: 2rem;
}
.description {
  min-height: calc(0.875rem * 1.5 * 3); // минимальная высота 2 строки (с учётом font-size и line-height)
}
.price {
  font-size: 2.25rem;
}
.month {
  font-size: 1rem;
}
.list {
  margin: 0;
  padding-left: 1rem;
  li {
    margin-bottom: 0.8rem;
    &::marker {
      color: var(--primary);
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.button-custom {
  max-width: 18rem;
}

.price-strike {
  vertical-align: top;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 110%;
    height: 1px;
    transform: rotate(330deg) translateY(-50%);
    background: #768192;
  }
}

.c-dark-theme {
  .price-strike {
    &:after {
      background: rgba(255, 255, 255, 0.6);
    }
  }
}

.tooltip-wrap {
  margin-left: 0.3rem;
  cursor: pointer;
  color: #fff;
  width: 1rem;
  height: 1rem;
}

.functions-title {
  margin-top: 3rem;
}

.select-custom {
  /deep/ option:before {
    content: "ddsd";
  }
}
.input-span-custom {
  font-size: 0.75rem;
  margin-right: 0.5rem;
}

.month-group {
  padding: 1rem;
  background-color: var(--third-card-bg);
}

.circle-color {
  display: inline-block;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.group-custom {
  /deep/ {
    .input-group-text {
      min-width: 2.8rem;
    }
    .form-group:first-child {
      margin-bottom: 0;
    }
  }
}
</style>
