var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    { staticClass: "shadow-none h-100 mb-0" },
    [
      _c(
        "CCardBody",
        { staticClass: "card-body-custom" },
        [
          _c(
            "div",
            { staticClass: "group-custom" },
            [
              _c("CInput", {
                attrs: {
                  prepend: "ru",
                  value: _vm.title.ru,
                  label: `${_vm.$t(
                    "lk.subscribeConstructor.subscriptionName"
                  )}:`,
                  placeholder: _vm.$t("lk.subscribeConstructor.name"),
                },
                on: {
                  input: function ($event) {
                    return _vm.$emit("setTitle", {
                      locale: "ru",
                      value: $event,
                    })
                  },
                },
              }),
              _c("CInput", {
                attrs: {
                  prepend: "en",
                  value: _vm.title.en,
                  placeholder: _vm.$t("lk.subscribeConstructor.name"),
                },
                on: {
                  input: function ($event) {
                    return _vm.$emit("setTitle", {
                      locale: "en",
                      value: $event,
                    })
                  },
                },
              }),
            ],
            1
          ),
          _c("CInput", {
            attrs: {
              value: _vm.tariffId,
              label: `${_vm.$t("lk.subscribeConstructor.subscriptionID")}:`,
              placeholder: "anyTariffId",
              disabled: "",
              description: _vm.$t(
                "lk.subscribeConstructor.requiredForTheServerSide"
              ),
            },
            on: {
              input: function ($event) {
                return _vm.$emit("setTariffId", $event)
              },
            },
          }),
          _c("v-select-input", {
            attrs: {
              options: _vm.colorOptions,
              searchable: false,
              clearable: false,
              placeholder: _vm.$t("lk.subscribeConstructor.chooseColor"),
            },
            scopedSlots: _vm._u([
              {
                key: "option",
                fn: function ({ option }) {
                  return [
                    _c("div", {
                      staticClass: "circle-color",
                      style: { background: option.value },
                    }),
                    _c("span", [_vm._v(_vm._s(option.label))]),
                  ]
                },
              },
              {
                key: "selected-option",
                fn: function ({ option }) {
                  return [
                    _c("div", {
                      staticClass: "circle-color",
                      style: { background: option.value },
                    }),
                    _c("span", [_vm._v(_vm._s(option.label))]),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.colorComputed,
              callback: function ($$v) {
                _vm.colorComputed = $$v
              },
              expression: "colorComputed",
            },
          }),
          _c(
            "div",
            { staticClass: "group-custom" },
            [
              _c("CInput", {
                attrs: {
                  prepend: "ru",
                  value: _vm.description.ru,
                  label: `${_vm.$t(
                    "lk.subscribeConstructor.subscriptionDescription"
                  )}:`,
                  placeholder: _vm.$t("lk.subscribeConstructor.description"),
                },
                on: {
                  input: function ($event) {
                    return _vm.$emit("setDescription", {
                      locale: "ru",
                      value: $event,
                    })
                  },
                },
              }),
              _c("CInput", {
                attrs: {
                  prepend: "en",
                  value: _vm.description.en,
                  placeholder: _vm.$t("lk.subscribeConstructor.description"),
                },
                on: {
                  input: function ($event) {
                    return _vm.$emit("setDescription", {
                      locale: "en",
                      value: $event,
                    })
                  },
                },
              }),
            ],
            1
          ),
          _c("label", [
            _vm._v(
              _vm._s(_vm.$t("lk.subscribeConstructor.subscriptionCost")) + ":"
            ),
          ]),
          _c(
            "div",
            { staticClass: "month-group" },
            _vm._l(_vm.monthList, function (item, i) {
              return _c(
                "CRow",
                { key: i, staticClass: "align-items-end mb-2" },
                [
                  _c(
                    "CCol",
                    { attrs: { col: "5" } },
                    [
                      _c("CInput", {
                        staticClass: "mb-1",
                        attrs: {
                          value: _vm.monthList[i].price,
                          label: _vm.monthList[i].name[_vm.locale],
                          placeholder: _vm.$t("lk.subscribeConstructor.price"),
                        },
                        on: {
                          input: function ($event) {
                            return _vm.setPrice(i, $event)
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "append-content",
                              fn: function () {
                                return [_vm._v("$")]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "CCol",
                    { attrs: { col: "7" } },
                    [
                      _c("CInput", {
                        staticClass: "mb-1",
                        attrs: {
                          value: _vm.monthList[i].oldPrice,
                          placeholder: _vm.$t("lk.subscribeConstructor.price"),
                        },
                        on: {
                          input: function ($event) {
                            return _vm.setOldPrice(i, $event)
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "prepend",
                              fn: function () {
                                return [
                                  _c("span", {
                                    staticClass: "input-span-custom",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t(
                                          "lk.subscribeConstructor.oldPrice"
                                        ) + ":"
                                      ),
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "append-content",
                              fn: function () {
                                return [_vm._v("$")]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                  _c("CCol", { attrs: { col: "12" } }, [
                    _vm.monthList[i].savePct
                      ? _c("span", { staticClass: "text-subscribe-save-pct" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "lk.subscribeConstructor.amountOfDiscount"
                                )
                              ) +
                              " " +
                              _vm._s(_vm.monthList[i].savePct) +
                              "% "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              )
            }),
            1
          ),
          _c("p", { staticClass: "font-weight-bold functions-title" }, [
            _vm._v(_vm._s(_vm.$t("lk.subscribeConstructor.mainFunctions"))),
          ]),
          _c(
            "div",
            _vm._l(_vm.functionList, function (item, i) {
              return _c("div", { key: i, staticClass: "group-custom" }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-items-center mb-3" },
                  [
                    _c(
                      "div",
                      { staticClass: "flex-grow-1" },
                      [
                        _c("CInput", {
                          attrs: { prepend: "ru", value: item.ru },
                          on: {
                            input: function ($event) {
                              _vm.functionList[i].ru = $event
                            },
                          },
                        }),
                        _c("CInput", {
                          staticClass: "mb-0",
                          attrs: { prepend: "en", value: item.en },
                          on: {
                            input: function ($event) {
                              _vm.functionList[i].en = $event
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "CButton",
                      {
                        staticClass: "shadow-none ml-2",
                        attrs: { color: "danger" },
                        on: {
                          click: function ($event) {
                            return _vm.functionList.splice(i, 1)
                          },
                        },
                      },
                      [_c("CIcon", { attrs: { name: "cil-x" } })],
                      1
                    ),
                  ],
                  1
                ),
              ])
            }),
            0
          ),
          _c(
            "CButton",
            {
              staticClass: "shadow-none",
              attrs: { color: "primary", variant: "outline" },
              on: {
                click: function ($event) {
                  return _vm.functionList.push({ en: "", ru: "" })
                },
              },
            },
            [
              _c("CIcon", { attrs: { name: "cil-plus" } }),
              _vm._v(
                " " + _vm._s(_vm.$t("lk.subscribeConstructor.addLine")) + " "
              ),
            ],
            1
          ),
          _c("p", { staticClass: "font-weight-bold functions-title" }, [
            _vm._v(_vm._s(_vm.$t("lk.subscribeConstructor.availablePages"))),
          ]),
          _c(
            "div",
            { staticClass: "month-group" },
            _vm._l(_vm.availablePages, function (item, i) {
              return _c("CInputCheckbox", {
                key: i,
                attrs: {
                  custom: "",
                  label: item.name[_vm.locale],
                  checked: item.enabled,
                },
                on: {
                  "update:checked": function ($event) {
                    item.enabled = $event
                  },
                },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }